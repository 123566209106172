<template>
    <div class="login-register-box clearfix">
        <!-- 登录 & 注册组件 -->
        <div class="login-register">
            <slot name="formBox"></slot>
        </div>
        <!-- 轮播图组件 -->
        <div class="login-register-carousel-box">
            <img v-if="$route.name == 'login'" 
                class="login-process-pic"
                :src="require('@/assets/images/login/process.png')" 
                alt="">
            <swiper 
                :options="swiperOption"
                v-else>
                <swiper-slide
                    v-for="item in carousel_img"
                    :key="item.id">
                    <el-image
                        :src="require('@/assets/images/login/'+item)"
                        fit="scale-down"
                        class="login-register-carousel-pic">
                        <!-- 加载中样式 -->
                        <template v-slot:placeholder>
                            <div class="image-slot">
                                <i class="el-icon-loading"></i>
                            </div>
                        </template>
                        <!-- 加载失败样式 -->
                        <template v-slot:error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </swiper-slide>
                <!-- 圆点导航 -->
                <template v-slot:pagination>
                    <div class="swiper-pagination swiper-pagination-white">
                    </div>
                </template>
            </swiper>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            swiperOption: {
                effect: 'fade',
                loop: false,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: true
                },
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            carousel_img: [
                "carousel01.png",
                "carousel02.png",
                "carousel03.png"
            ],
        }
    },
}
</script>

<style scoped>
/* 组件盒子 */
.login-register-box{
    display:flex;
    align-items: center;
    overflow: hidden;
    min-height: 100%;
}
/* 登录 & 注册 */
.login-register{
    float: left;
    width: 600px;
    padding: 0 50px 0 100px;
}
/* 轮播图 */
.login-register-carousel-box{
    flex: 1;
    overflow: hidden;
}
.login-register-carousel-pic{
    width: 100%;
    height: 500px;
    background-color: #fff;
}
.login-process-pic{
    width: 100%;
    margin-top: 80px;
    padding-right: 100px;
}
</style>